import React from "react";
import styled from "styled-components";
import SEO from "../components/seo";
import DemoCreator from "../components/demoCreator";
import quickcrmLogo from "../images/QuickCRM-prosty-CRM-dla-dynamicznych-zespolow.png";

const LoginWrapper = styled.div`
  background: #f1f5f8;
  display: flex;
  height: 100vh;
`;

const ZalogujCrm = () => {
  return (
    <LoginWrapper>
      <SEO
        title="Zaloguj się do systemu"
        description="Zaloguj się do swojego systemu QuickCRM"
      />
      <DemoCreator
        header="Zaloguj się"
        submitButtonText="Zaloguj się"
        type="login"
        // type="create"
        logo={quickcrmLogo}
      />
    </LoginWrapper>
  );
};

export default ZalogujCrm;
